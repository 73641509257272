import axios from 'axios';
import store from '@/store/index.js';
import router from '@/router/index.js';

/**
 * Make an AXIOS instance for connecting to Django API
 * @type {AxiosInstance}
 */
const $axios_api = axios.create({
    baseURL: window.VUE_API_URL + '/api/1.0/',
    timeout: 8000,
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true,
    // // Make Axios play nice with Django CSRF
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
});

/**
 * Interceptor for Axios instance catching the 200/201 and the 401
 */
$axios_api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    // Do something with request error
    if (error.response.status === 401) {
        // clear authentication data
        store.commit('auth/AuthReset');
        // save current page to return to after logging in
        if (router['history'].current.name !== 'Home') router.push({name: 'Home'});
    }
    return Promise.reject(error);
});


/**
 * Make an AXIOS instance for connecting to the login/logout/userinfo of the Django API
 * @type {axios.AxiosInstance}
 */
const $api_admin = axios.create({
    baseURL: window.VUE_API_URL + '/account/',
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 8000,
    withCredentials: true,
    // Make Axios play nice with Django CSRF
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
});


export default $axios_api;
export { $axios_api, $api_admin };