import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced: true,
    state() {
        return {
            user: {
                username: null,
                organisations: [],
                active_org: null,
                email: "",
                ory_id: "",
                expires_at: -1
            },
            isSuperAdmin: false,
            authenticated: "",
            status: "",
            lastKnownOrg: [], // this one is persistent
            apiUrl: "",
            baseUrl: ""
        };
    },
    mutations,
    actions,
    getters
};

// TODO lastKnownOrg should hold all known users with their last known Organisation
