import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced: true,
    state() {
        return {
            organisations: null,
            zones: null,
            polys:null,
            sensor: null,
            nodes: null,
            nodes2: null,
            nodesingle: null,
            onlineList: null,
            metaList: null,
            nodeList: null,
            scheduleList: null,
        };
    },
    mutations,
    actions,
    getters
};
