import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store/index.js';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import i18n from './i18n'
import axios from 'axios';

Vue.config.productionTip = false

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

Vue.component('loading-bar', () => import('@/components/Standard/LoadingBar.vue'));
Vue.component('message', () => import('@/components/Standard/Message.vue'));

// Global configuration via environment variables
// These settings are plugged in via conf/env-config.js  (directly used in index.html)
Vue.prototype.$API_URL = window.VUE_API_URL;

const app = new Vue({
    vuetify,
    router,
    store: store,
    i18n,
    render: h => h(App)
}).$mount('#app')


if (! Vue.prototype.$API_URL) {
    // remind us to set VUE_API_URL in docker runner
    console.log("Warning: deployment error, empty $API_URL.");
} else {
    store.commit('auth/setBaseUrl', Vue.prototype.$API_URL); // sets the api url automatically
}