const defaultAuthState = () => {
    return {
        user: {
            username: null,
            organisations: [],
            active_org: null,
            email: "",
            ory_id: "",
            expires_at: -1
        },
        isSuperAdmin: false,
        authenticated: "",
        status: "",
        apiUrl: ""
    };
};

export default defaultAuthState;
