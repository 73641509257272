import defaultScheduleState from "@/store/modules/schedule/defaultScheduleState";
import { cloneDeep as _cloneDeep } from 'lodash';

export default {
    clearScheduleState(state) {
        Object.assign(state, defaultScheduleState());
    },
    addEvent(state, event) {
        state.events.push(event);
    },
    addEvents(state, events){
        // TODO check for duplicates etc
        for (let event of events) state.events.push(event);
    },
    removeEvent(state, date) {
        // TODO check if there are more events on a certain date
        for (let i = 0; i < state.events.length; i++){
            if (state.events[i].date === date){
                state.events.splice(i, 1);
            }
        }
    },
    changeWeekInfo(state, info) {
        state.weekinfo = info;
    },
    setEventsRange(state, rangeStart, rangeEnd) {
        const eventsRange = [];
        state.events.forEach((el) => {
            if (el.date > rangeStart && el.date < rangeEnd) eventsRange.push(el);
        })
        state.eventsRange = eventsRange;
    },
    setCurrent(state, payload) {
        state.current = payload;
    },
    saveSchedule(state, payload) {
        // payload = { schedule_uid: schedule_uid, schedule: schedule }
        state.singleSchedule = _cloneDeep(payload.schedule);
    },
    setSchedules(state, data) {
        state.schedules = _cloneDeep(data);
    },
    searchSchedule(state, payload) {
        state.singleSchedule = _cloneDeep(payload.singleSchedule);

        console.log('searchSchedule -> ', payload.singleSchedule);

        payload.singleSchedule.schedules.map((io) => {
            return {
                io_id: io.io_id,
                profiles: io.profiles
            }
        })
    },
    setHighestScheduleId(state, payload) {
        if (payload) {
            state.highestScheduleId = payload.highest;
        }
    },
    setError(state, error) {
        state.error = error;
    },
    resetError(state) {
        state.error = '';
    },
    setQueryData(state, queryData) {
        state.queryData = _cloneDeep(queryData);
    },
    lastAdded(state, payload) {
        state.lastAdded.id = payload.id;
        state.lastAdded.uid = payload.uid;
        state.lastAdded.version = payload.version;
    }
};
