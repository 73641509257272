<template>
    <v-app>
         <v-app-bar app class="ma-0 pa-0" color="primary" tile>
            <Menubar />
         </v-app-bar>

          <v-main >
            <v-container fluid>
                <info-message />
                <router-view></router-view>
            </v-container>
          </v-main>

          <v-footer app class="ma-0 pa-0" color="primary">
            <Footer />
          </v-footer>
    </v-app>
</template>

<script>
import Menubar from '@/components/Menubar.vue';
import Footer from '@/components/Standard/Footer.vue';
import InfoMessage from "@/components/Standard/InfoMessage";



export default {
    name: 'App',
    components: {
        InfoMessage,
        Menubar,
        Footer,
  }
}
</script>

<style lang="css">
.v-calendar-daily__scroll-area {
    overflow-y: scroll !important;
}
</style>
