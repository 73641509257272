<template>
    <div class="d-flex mainFoot">
        <div class="d-flex justify-center buttonsBar">
            <v-btn
                    :id="`footer-btn-${link1.id}`"
                    v-for="link1 in toLinks"
                    :key="link1.id"
                    class="ma-1 text-decoration-none grey--text"
                    text
                    color="grey"
                    :to="link1.route"
            >
                {{ $t(link1.title) }}
            </v-btn>
            <v-btn
                    :id="`footer-btn-${link1.id}`"
                    v-for="link1 in hrefLinks"
                    :key="link1.id"
                    class="ma-1 text-decoration-none grey--text"
                    text
                    color="grey"
                    :href="link1.route"
                    target="_blank"
            >
                {{ $t(link1.title) }}
            </v-btn>
            <lang-choose  />
        </div>
        <div class="d-flex versionBar">
            <div class="my-auto grey--text align-items-center">v{{ websiteVersion ? websiteVersion : '?.?.?' }}</div>
        </div>
    </div>
</template>
<script>
import LangChoose from "@/components/Standard/LangChoose";
import Vue from "vue";
import { version } from '@/../package.json';

export default {
    data:() => ({
        links:[
            {
                id: 1,
                title: 'menu.buttons.home',
                route: '/',
                newPage: false
            },
            {
                id: 2,
                title: 'menu.buttons.faq',
                route: '/faq',
                newPage: false
            },
            {
                id: 3,
                title: 'menu.buttons.aboutUs',
                route: 'https://www.intemo.com/over-ons/',
                newPage: true
            },
            {
                id: 4,
                title: 'menu.buttons.contactUs',
                route: 'https://www.intemo.com/contact/',
                newPage: true
            },

            {
                id: 5,
                title: 'menu.buttons.apiDocs',
                route: Vue.prototype.$API_URL + '/docs/',
                newPage: true
            }
        ],
        websiteVersion: version
    }),
    components: {
        LangChoose,
    },
    computed: {
        hrefLinks(){
            return this.links.filter(link => link.newPage === true);
        },
        toLinks() {
            return this.links.filter(link => link.newPage === false);
        }
    },
}
</script>
<style scoped>
.mainFoot {
    border-top: lightgrey 1px solid;
    box-shadow: 0 -2px 8px darkgray;
    margin-top: 5px;
    width: 100%;
}
.buttonsBar {
    width: 80%;
    display: flex;
    margin: 0 0 0 20vw;
}
.versionBar {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    margin-right: 6em;
}
</style>
