import Vue from "vue";
import {$axios_api} from "@/services/http-common.js";

// get data for a single node
async function getData({ commit }, sensornr) {
    await $axios_api.get('nodes/' + sensornr + '')
        .then( response => {
            // TODO this is hardcoded data for TIZEN (id 319007 hw_id 000140BD25EE88BE)
            const data = response.data;
            if (sensornr === 319007){
                data.hardware.modules.push({
                    "port_id": 248,
                    "type": "p1_meter"

                })
            }
            commit('setNodesingle', response.data);
            return response.data;
        }).catch((error) => {
            commit('setNodesingle', null);
            console.log("GET SINGLE DATA: There was an error : " + error);
        });
}
// get last data for one specific node
async function getLastData({ commit }, sensornr) {
    // TODO this is hardcoded data for TIZEN (id 319007 hw_id 000140BD25EE88BE)
    await $axios_api.get('nodes/' + sensornr + '/last')
        .then( response => {
            const data = response.data;
            if (sensornr === 319007){

                data.sensors.push({
                    "port_id": 248,
                    "measurements": {
                        "V1.mV": {
                            "value": 231342,
                            "unit": "mV"
                        },
                        "V2.mV": {
                            "value": 221456,
                            "unit": "mV"
                        },
                        "V3.mV": {
                            "value": 223753,
                            "unit": "mV"
                        },
                        "I1.mA": {
                            "value": 707,
                            "unit": "mA"
                        },
                        "I2.mA": {
                            "value": 1461,
                            "unit": "mA"
                        },
                        "I3.mA": {
                            "value": 631,
                            "unit": "mA"
                        },
                        "P.L1.W": {
                            "value": (231 * 0.707).toFixed(2),
                            "unit": "W"
                        },
                        "P.L2.W": {
                            "value": (221 * 1.461).toFixed(2),
                            "unit": "W"
                        },
                        "P.L3.W": {
                            "value": (223 * 0.631).toFixed(2),
                            "unit": "W"
                        },
                        "Energy.Consumed.Wh": {
                            "value": 19751.0,
                            "unit": "Wh"
                        }
                    }
                })
            }
            commit('setNodeLast', response.data);
            return response.data;
        }).catch((error) => {
            commit('setNodeLast', null);
            console.log("GET LAST DATA: There was an error : " + error);
        });
}
function getNodesList ({ commit, rootGetters }) {
    let list = [];
    const org = rootGetters['auth/StateUser'].active_org;
    return $axios_api.get(`orgs/${org}/nodes`)
    .then((response) => {
        response.data.nodes.forEach((element) => list.push(element.substr(15, element.length - 1)));
        list = list.join(",");
        commit('setNodesList', list);
        return response.data;
    })
    .catch((error) => {
        commit('setNodesList', null);
        console.log("NODES: There was an error getting the nodes list : " + error);
    });
}
function getNodesOnline({ commit }, nodesList) {
    let myData = [];
    return $axios_api.get(`nodes/last/${nodesList}?fields=connection`)
    .then((response) => {
        response.data.forEach((element) => {
            myData.push({
                id: element.id,
                connection: {
                    last_seen: element.connection.last_seen,
                    online: element.connection.online
                }
            });
        });
        commit('setOnlineList', myData);
        return response.data;
    })
    .catch((error) => {
        commit('setOnlineList', null);
        console.log("NODES: There was an error getting the online statuses : " + error);
    });
}
// get metadata for a list of nodes
function getMetaNodes ({ commit }, nodesList) {
    let nodeMeta = [];
    return $axios_api.get(`nodes/meta/${nodesList}`)
    .then((response) => {
        nodeMeta = response.data;

        return response.data;
    })
    .catch((error) => {
        console.log("There was an error during the get Multi Nodes Meta Data.. : " + error);
    })
    .finally(() => commit('setNodeMeta', nodeMeta));
}
/**
 * @function getSchedulesNodes
 * @desc returns the schedules which are active on the nodes that are given as a parameter.
 *
 * @param commit
 * @param nodesList List of nodes the info will be gotten from
 * @returns {Promise<void>}
 */
function getSchedulesNodes({commit}, nodesList) {
    let nodesSchedules = [];
    return $axios_api.get(`nodes/schedule/${nodesList}`)
    .then((response) => {
        response.data.forEach((elem) => {
            nodesSchedules.push({
                id: elem.id,
                schedule: elem.schedule
            });
        });
        commit('setScheduleList', nodesSchedules);
        return response.data;
    })
    .catch((error) => {
        commit('setScheduleList', null);
        console.log("NODES: There was an error getting the schedule statuses : " + error);
    });
}

function getZones({commit, rootGetters}) {
    let zones = [];
    const orga = rootGetters['auth/StateUser'].active_org;
    return $axios_api.get(`orgs/${orga}/zones`)
        .then((response) => {
            if (response.data.zones.length > 0){
                response.data.zones.forEach((el) => {
                    zones.push({
                        id: el.id,
                        name: el.name,
                        city: el.city,
                        shape: el.shape ? el.shape : null,
                        nodes: el.nodes ? el.nodes : null,
                    });
                });
            }
            commit('setZoneList', zones);
            return response.data;
        })
        .catch((error) => {
            commit('setZoneList', null);
            console.log('ZONES: there was an error getting the zones : ' + error);
        });
}


function savePolys({commit}, polys) {
    commit('setPolys', polys);
}



export default {
    getData,
    getLastData,
    getNodesList,
    getNodesOnline,
    getMetaNodes,
    getSchedulesNodes,
    getZones,
    savePolys
};
