import { render, staticRenderFns } from "./Menubar.vue?vue&type=template&id=0da73020"
import script from "./Menubar.vue?vue&type=script&lang=js"
export * from "./Menubar.vue?vue&type=script&lang=js"
import style0 from "./Menubar.vue?vue&type=style&index=0&id=0da73020&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports